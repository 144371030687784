/*
Navbar.js
Adam Berger
July 2023
*/

// 3P imports
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import ModalWithAck from "./ModalWithAck";
import ModalWithoutAck from "./ModalWithoutAck";
import PropTypes from "prop-types";

import axios from "axios";

// 1P imports
import LoginButton from "components/LoginButton";
import * as Constants from "components/Constants";
import FeedbackModal from "./FeedbackModal";
import InfoModal from "./InfoModal";

//css imports
import "./navbar.css";

//icon imports
import LogoutButton from "./LogoutButton";
import wandWhite from "assets/icons/wand-white.svg";
import hamburger from "assets/icons/hamburger.svg";
import x from "assets/icons/x.svg";
import listDashes from "assets/icons/list-dashes.svg";
import upload from "assets/icons/upload.svg";
import globe from "assets/icons/globe.svg";
import pen from "assets/icons/pen.svg";

const NO_INTERNET_CONNECTION_MSG =
  "It looks like this device is not connected to the Internet. This app is not much use without the Internet.";

const Navbar = ({ showMenu = true }) => {
  const { user, isAuthenticated } = useAuth0();
  const [modalIsActive, setModalIsActive] = useState(false);

  // Screen Size State
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const closeRecipeMenus = () => {
    setDesktopRecipeModalDisplay(false);
    setMobileRecipeModalDisplay(false);
    setMobileMenuDisplay(false);  
  };

  useEffect(() => {
    //update screen size state
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    const closeMenusOnResize = () => {
      if (screenSize.width >= 1025) {
        setMobileMenuDisplay(false);
        setMobileRecipeModalDisplay(false);
      } else if (screenSize.width < 1025) {
        setDesktopRecipeModalDisplay(false);
      }
    };
    closeMenusOnResize();

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenSize]);

  // Mobile Nav States
  const [mobileMenuDisplay, setMobileMenuDisplay] = useState(false);
  const [mobileRecipeModalDisplay, setMobileRecipeModalDisplay] =
    useState(false);
  const [desktopRecipeModalDisplay, setDesktopRecipeModalDisplay] =
    useState(false);
  const menuRef = useRef();

  const desktopRecipeModalRef = useRef(null);
  const mobileRecipeModalRef = useRef(null);


  // Feedback Modal States
  const [feedback, setFeedback] = useState(null);
  const [feedbackModalIsActive, setFeedbackModalIsActive] = useState(false);
  const [thankYouModalIsActive, setThankYouModalIsActive] = useState(false);
  const [errorModalIsActive, setErrorModalIsActive] = useState(false);
  const [infoModalIsActive, setInfoModalIsActive] = useState(false);

  async function submitFeedback() {
    if (!feedback || !feedback.trim()) {
      return;
    }
    const encodedUser = Constants.encodeUser(user);
    const encodedFeedback = Constants.encodeUserInputForURI(feedback);

    const url = Constants.generateSecureApiUrl(
      Constants.APIG_FEEDBACK_RESOURCE,
      "user=" + encodedUser + "&feedback=" + encodedFeedback
    );

    try {
      await axios(url);
      resetFeedbackForm();
      setThankYouModalIsActive(true);
    } catch (error) {
      console.error("Error submitting feedback", error);
      setErrorModalIsActive(true);
    }
    resetFeedbackForm();
    setThankYouModalIsActive(true);
  }

  function resetFeedbackForm() {
    setFeedbackModalIsActive(false);
    setFeedback("");
  }

  if (user === null) {
    return <div>Loading ...</div>;
  }

  function renderLoginButton() {
    if (!isAuthenticated) {
      return <LoginButton />;
    }
  }

  useEffect(() => {
    /* remove the mobile menu if user clicks anywhere outside it */
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeRecipeMenus();
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    /* remove the 'New Recipe' dropdown menu if user clicks anywhere outside it */
    function handleClickOutside(event) {
      // If desktop is open and click is outside the desktop container:
      if (
        desktopRecipeModalDisplay &&
        desktopRecipeModalRef.current &&
        !desktopRecipeModalRef.current.contains(event.target)
      ) {
        setDesktopRecipeModalDisplay(false);
      }
      // If mobile is open and click is outside the mobile container:
      if (
        mobileRecipeModalDisplay &&
        mobileRecipeModalRef.current &&
        !mobileRecipeModalRef.current.contains(event.target)
      ) {
        setMobileRecipeModalDisplay(false);
      }
    }
  
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [desktopRecipeModalDisplay, mobileRecipeModalDisplay]);

  
  function dismissModal() {
    setModalIsActive(false);
  }

  function hamburgerClick() {
    if (!mobileMenuDisplay) {
      setMobileRecipeModalDisplay(false);
    }
    setMobileMenuDisplay(!mobileMenuDisplay);
  }

  function mobileRecipeClick(e) {
    setMobileMenuDisplay(false);
    e.stopPropagation();
    setMobileRecipeModalDisplay(!mobileRecipeModalDisplay);
  }

  function desktopRecipeClick(e) {
    e.stopPropagation();
    setDesktopRecipeModalDisplay(!desktopRecipeModalDisplay);
  }

  function openFeedbackModal() {
    setFeedbackModalIsActive(true);
  }

  function openInfoModal() {
    setInfoModalIsActive(true);
  }

  function toggleInfoModal() {
    setInfoModalIsActive(!infoModalIsActive);
  }

  function closeInfoModal() {
    setInfoModalIsActive(false);
  }

  function toggleInternetModal() {
    setModalIsActive(!modalIsActive);
  }

  function toggleFeedbackModal() {
    setFeedbackModalIsActive(!feedbackModalIsActive);
  }

  return (
    <>
      {modalIsActive && (
        <ModalWithAck
          toggle={toggleInternetModal}
          backdrop={true}
          title="No Internet connection"
          message={NO_INTERNET_CONNECTION_MSG}
          onClose={dismissModal}
        />
      )}

      {infoModalIsActive && (
        <InfoModal
          toggle={toggleInfoModal}
          backdrop={true}
          onOpenFeedback={() => {
            closeInfoModal();
            openFeedbackModal();
          }}
          onClose={closeInfoModal}
        ></InfoModal>
      )}

      {feedbackModalIsActive && (
        <FeedbackModal
          toggle={toggleFeedbackModal}
          backdrop={true}
          feedback={feedback}
          onFeedbackChange={setFeedback}
          onSubmitFeedback={submitFeedback}
          onCancel={resetFeedbackForm}
        ></FeedbackModal>
      )}

      {thankYouModalIsActive && (
        <ModalWithoutAck
          toggle={() => {
            setThankYouModalIsActive(!thankYouModalIsActive);
          }}
          backdrop={true}
          success={true}
          title="Thank you for your feedback!"
          message="Your input is greatly appreciated and will help us improve. We value your opinion and look forward to serving you even better in the future."
          delay={2500}
          onClose={() => {
            setThankYouModalIsActive(false);
          }}
        />
      )}

      {errorModalIsActive && (
        <ModalWithAck
          toggle={() => {
            setErrorModalIsActive(!errorModalIsActive);
          }}
          backdrop={true}
          error={true}
          title="Error"
          message="It looks like an unexpected error occurred, please try again."
          onClick={() => {
            setErrorModalIsActive(false);
          }}
          onTryAgain={() => {
            setErrorModalIsActive(false);
            setFeedbackModalIsActive(true);
          }}
        />
      )}

      <section>
        <nav className="nav-container-desktop">
          <div className="left-nav">
            <Link to="/browse" className="logo">
              <img
                className="logo-img"
                src={Constants.LOGO_IMAGE}
                alt="kasserole logo"
              />
            </Link>
          </div>

          {isAuthenticated && (
            <div className="right-nav">
              <div>
                <ul className="nav-side-menu">

                  <li className="nav-items">
  
                  <div className="nav-links" onClick={openInfoModal}>
                        {" "}Help{" "}
                  </div>
                  </li>
                  <li className="nav-items">
                    <LogoutButton />
                  </li>

                  <li className="nav-btn-dark">
                    <div className="nav-links" onClick={(e) => desktopRecipeClick(e)}>
                      <div className="nav-btn log-in-btn">
                        <img
                          className="btn-icon new-recipe-btn-icon"
                          src={wandWhite}
                        />
                        <div>New Recipe</div>
                      </div>
                    </div>
                  </li>
                </ul>

                <div
                  ref={desktopRecipeModalRef}
                  className={
                    desktopRecipeModalDisplay
                      ? "new-recipe-dropdown-container"
                      : "new-recipe-dropdown-container-hidden"
                  }
                >
                  <div className="new-recipe-dropdown">
                    <li className="new-recipe-dropdown-title">
                      <div className="recipe-dropdown-bold">
                        Craft a new recipe:
                      </div>
                    </li>
                    <li className="new-recipe-dropdown-item">
                      <Link
                        className="recipe-dropdown-text"
                        to="/createFromIngredients"
                        onClick={closeRecipeMenus}
                      >
                        <img src={listDashes} />
                        Type some ingredients
                      </Link>
                    </li>
                    <li className="new-recipe-dropdown-item">
                      <Link
                        className="recipe-dropdown-text"
                        to="/createFromImage"
                        onClick={closeRecipeMenus}
                      >
                        <img src={upload} />
                        Upload a picture from a cookbook
                      </Link>
                    </li>
                    <li className="new-recipe-dropdown-item">
                      <Link
                        className="recipe-dropdown-text"
                        to="/createFromURL"
                        onClick={closeRecipeMenus}
                      >
                        <img src={globe} />
                        Type the URL of a recipe
                      </Link>
                    </li>
                    <li className="new-recipe-dropdown-item">
                      <Link
                        className="recipe-dropdown-text"
                        onClick={(e) => {
                          e.preventDefault(); // Prevent React Router's default navigation
                          closeRecipeMenus(); 
                          setTimeout(() => {
                            // this forces reload of the edit view; otherwise nothing will happen if user
                            // is already on this page (i.e. editing an existing recipe)
                            window.location.assign("/edit/new"); 
                          }, 0);  
                        }}
                      >
                        <img src={pen} />
                        Build a recipe from scratch
                      </Link>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isAuthenticated && (
            <div className="right-nav">{renderLoginButton()}</div>
          )}
        </nav>
        <nav className="nav-container-mobile">
          <div className="nav-mobile-top">
            <Link to="/browse" className="mobile-logo">
              <img
                className="mobile-logo-img"
                src={Constants.LOGO_IMAGE}
                alt="kasserole logo"
              />
            </Link>
            <div className="mobile-nav-right">
              <div>
                <button onClick={(e) => mobileRecipeClick(e)} className="recipe-btn">
                  New Recipe
                </button>
              </div>

              <div className="hamburger-container">
                <img
                  className="hamburger"
                  onClick={hamburgerClick}
                  src={mobileMenuDisplay === false ? hamburger : x}
                />
              </div>
            </div>
          </div>

          {isAuthenticated && showMenu && (
            <nav
              className={mobileMenuDisplay === false ? "hidden-mobile-nav" : ""}
            >
              <ul className="nav-mobile-menu">
                <li className="nav-items">
                  <Link to="/browse" className="nav-links">
                    {" "}
                    Recipes{" "}
                  </Link>
                </li>

                <li className="nav-items">
                  <div className="nav-links" onClick={openInfoModal}>
                    {" "}
                    Help{" "}
                  </div>
                </li>

                <li className="nav-items">
                  <LogoutButton />
                </li>

                <div></div>
              </ul>
            </nav>
          )}

          {!isAuthenticated && (
            <div className="right-nav">{renderLoginButton()}</div>
          )}
        </nav>
        <div
          ref={mobileRecipeModalRef}
          className={
            mobileRecipeModalDisplay
              ? "new-recipe-modal-container"
              : "new-recipe-modal-container-hidden"
          }
        >
          <div className="new-recipe-modal">
            <li className="new-recipe-modal-title">
              <div className="recipe-modal-bold">Craft a new recipe?</div>
            </li>
            <li className="new-recipe-modal-item">
              <Link
                className="recipe-modal-text"
                to="/createFromIngredients"
                onClick={closeRecipeMenus}
              >
                <img src={listDashes} />
                Type some ingredients
              </Link>
            </li>
            <li className="new-recipe-modal-item">
              <Link
                className="recipe-modal-text"
                to="/createFromImage"
                onClick={closeRecipeMenus}
              >
                <img src={upload} />
                Upload a picture from a cookbook
              </Link>
            </li>
            <li className="new-recipe-modal-item">
              <Link
                className="recipe-modal-text"
                to="/createFromURL"
                onClick={closeRecipeMenus}
              >
                <img src={globe} />
                Type the URL of a recipe
              </Link>
            </li>
            <li className="new-recipe-modal-item">
              <Link
                className="recipe-modal-text"
                to="/edit/new"
                onClick={closeRecipeMenus}
              >
                <img src={pen} />
                Build a recipe from scratch
              </Link>
            </li>
          </div>
        </div>
      </section>
      <div className="desktop-nav-spacer"></div>
      <div className="mobile-nav-spacer"></div>
    </>
  );
};

export default Navbar;

Navbar.propTypes = {
  showMenu: PropTypes.bool,
};
