import { useState, useCallback, useEffect, useRef } from 'react';
import { useInstantSearch } from 'react-instantsearch-hooks-web';
import { INITIAL_NUTRITIONAL_FILTER_STATE, FILTER_MAPPINGS, INITIAL_CATEGORIES } from '../../settings';
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from '../../components/Constants';

const STORAGE_KEY = 'recipe_filters';
const UPDATE_DEBOUNCE_MS = 300;

export const useFilterState = () => {
  const { user } = useAuth0();
  const { setIndexUiState, indexUiState } = useInstantSearch();
  
  const [isUpdating, setIsUpdating] = useState(false);
  const updateTimeoutRef = useRef(null);
  const pendingStateRef = useRef(null);

  // Load initial state from localStorage
  const loadInitialState = () => {
    try {
      const saved = localStorage.getItem(STORAGE_KEY);
      if (saved) {
        const parsed = JSON.parse(saved);
        return {
          nutritionalFilters: parsed.nutritionalFilters || INITIAL_NUTRITIONAL_FILTER_STATE,
          categoryFilters: parsed.categoryFilters || INITIAL_CATEGORIES,
          searchQuery: indexUiState?.query || parsed.searchQuery || '',
          onlyShowMyRecipes: parsed.onlyShowMyRecipes || false,
          showSearchFilters: parsed.showSearchFilters || false,
        };
      }
    } catch (error) {
      console.error('Error loading filter state:', error);
      // Return defaults on error
      return {
        nutritionalFilters: INITIAL_NUTRITIONAL_FILTER_STATE,
        categoryFilters: INITIAL_CATEGORIES,
        searchQuery: indexUiState?.query || '',
        onlyShowMyRecipes: false,
        showSearchFilters: false,
      };
    }
    return {
      nutritionalFilters: INITIAL_NUTRITIONAL_FILTER_STATE,
      categoryFilters: INITIAL_CATEGORIES,
      searchQuery: indexUiState?.query || '',
      onlyShowMyRecipes: false,
      showSearchFilters: false,
    };
  };

  const initialState = loadInitialState();
  
  // Initialize state
  const [nutritionalFilters, setNutritionalFilters] = useState(initialState.nutritionalFilters);
  const [categoryFilters, setCategoryFilters] = useState(initialState.categoryFilters);
  const [searchQuery, setSearchQuery] = useState(initialState.searchQuery);
  const [onlyShowMyRecipes, setOnlyShowMyRecipes] = useState(initialState.onlyShowMyRecipes);
  const [showSearchFilters, setShowSearchFilters] = useState(initialState.showSearchFilters);

  // Build the complete filter query
  const buildFilterQuery = useCallback((filters = {}) => {
    const {
      nutritionalFiltersOverride = nutritionalFilters,
      categoryFiltersOverride = categoryFilters,
      onlyShowMyRecipesOverride = onlyShowMyRecipes
    } = filters;

    const filterParts = [];
    
    // Add nutritional filters
    const nutritionalFilterQueries = Object.entries(nutritionalFiltersOverride)
      .filter(([, value]) => value)
      .map(([key]) => FILTER_MAPPINGS[key]);
    
    if (nutritionalFilterQueries.length > 0) {
      filterParts.push(`(${nutritionalFilterQueries.join(' AND ')})`);
    }
    
    // Add category filters
    const selectedCategories = categoryFiltersOverride
      .filter(cat => cat.checked)
      .map(cat => `category:"${cat.label}"`);
    
    if (selectedCategories.length > 0) {
      filterParts.push(`(${selectedCategories.join(' OR ')})`);
    } else {
      filterParts.push('NOT category:"none"');
    }
    
    // Add my recipes filter
    if (onlyShowMyRecipesOverride && user) {
      filterParts.push(`userid:"${Constants.encodeUser(user)}"`);
    }
    
    return filterParts.length > 0 ? filterParts.join(' AND ') : '';
  }, [nutritionalFilters, categoryFilters, onlyShowMyRecipes, user]);

  // Helper function to save state to localStorage
  const saveState = useCallback(() => {
    const state = {
      nutritionalFilters,
      categoryFilters,
      searchQuery,
      onlyShowMyRecipes,
      showSearchFilters,
    };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
  }, [nutritionalFilters, categoryFilters, searchQuery, onlyShowMyRecipes, showSearchFilters]);

  // Helper function to handle Algolia updates and loading states
  const updateWithLoading = useCallback((updateFn) => {
    setIsUpdating(true);
    updateFn();
    saveState();
    
    if (updateTimeoutRef.current) {
      clearTimeout(updateTimeoutRef.current);
    }
    updateTimeoutRef.current = setTimeout(() => {
      setIsUpdating(false);
    }, UPDATE_DEBOUNCE_MS);
  }, [saveState]);

  // Update search query
  const updateSearchQuery = useCallback((query) => {
    const normalizedQuery = query || '';
    setSearchQuery(normalizedQuery);
    
    updateWithLoading(() => {
      // When clearing the search, ensure we reset the query in Algolia
      if (!normalizedQuery) {
        setIndexUiState(state => ({
          ...state,
          page: 0,
          query: undefined, // This ensures Algolia properly resets the search
          filters: buildFilterQuery()
        }));
      } else {
        setIndexUiState(state => ({
          ...state,
          page: 0,
          query: normalizedQuery,
          filters: buildFilterQuery()
        }));
      }
    });
  }, [setIndexUiState, buildFilterQuery, updateWithLoading]);

  // Toggle functions
  const toggleCategoryFilter = useCallback((categoryLabel) => {
    const newCategoryFilters = categoryFilters.map(cat => 
      cat.label === categoryLabel 
        ? { ...cat, checked: !cat.checked }
        : cat
    );
    setCategoryFilters(newCategoryFilters);
    
    updateWithLoading(() => {
      setIndexUiState(state => ({
        ...state,
        filters: buildFilterQuery()
      }));
    });
  }, [setIndexUiState, buildFilterQuery, categoryFilters, updateWithLoading]);

  const toggleNutritionalFilter = useCallback((filterKey) => {
    const newNutritionalFilters = {
      ...nutritionalFilters,
      [filterKey]: !nutritionalFilters[filterKey]
    };
    setNutritionalFilters(newNutritionalFilters);
    
    updateWithLoading(() => {
      setIndexUiState(state => ({
        ...state,
        filters: buildFilterQuery()
      }));
    });
  }, [setIndexUiState, buildFilterQuery, nutritionalFilters, updateWithLoading]);

  const toggleOnlyShowMyRecipes = useCallback((value) => {
    setOnlyShowMyRecipes(value);
    
    updateWithLoading(() => {
      setIndexUiState(state => ({
        ...state,
        filters: buildFilterQuery()
      }));
    });
  }, [setIndexUiState, buildFilterQuery, updateWithLoading]);

  const toggleSearchFilters = useCallback(() => {
    setShowSearchFilters(prev => !prev);
    saveState();
  }, [saveState]);

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (updateTimeoutRef.current) {
        clearTimeout(updateTimeoutRef.current);
      }
    };
  }, []);

  return {
    searchQuery,
    nutritionalFilters,
    categoryFilters,
    onlyShowMyRecipes,
    showSearchFilters,
    isUpdating,
    updateSearchQuery,
    toggleCategoryFilter,
    toggleNutritionalFilter,
    toggleOnlyShowMyRecipes,
    toggleSearchFilters,
    buildFilterQuery,
  };
}; 