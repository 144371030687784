import PropTypes from "prop-types";
import React from "react";
import {Checkbox} from "../base/Checkbox";
import {nfilters} from "../../settings";

export const NutritionalFilterCheckboxes = ({
    filters,
    onToggle,
}) => {
    return (
        <div className="nutritional-filter-container">
            {nfilters.map((filter) => (
                <div key={filter.name}>
                    <Checkbox
                        checked={filters[filter.name]}
                        onChange={() => onToggle(filter.name)}
                        label={filter.label}
                    />
                </div>
            ))}
            <br/>
        </div>
    );
};

NutritionalFilterCheckboxes.propTypes = {
    filters: PropTypes.object.isRequired,
    onToggle: PropTypes.func.isRequired,
};