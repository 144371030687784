/*
App.js
Adam Berger
July 2023 
*/

// Third party 
import React, { useEffect, useMemo } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes } from 'react-router-dom';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import { history } from 'instantsearch.js/es/lib/routers';

// first-party 
import { AuthenticationGuard } from "./components/authentication-guard";
import * as Constants from 'components/Constants';

// Configure routing
const routing = {
  router: history(),
  stateMapping: {
    stateToRoute(uiState) {
      const indexUiState = uiState[Constants.ALGOLIA_INDEX_NAME];
      return {
        query: indexUiState.query,
        page: indexUiState.page,
        filters: indexUiState.filters,
        hitsPerPage: indexUiState.hitsPerPage,
      };
    },
    routeToState(routeState) {
      return {
        [Constants.ALGOLIA_INDEX_NAME]: {
          query: routeState.query,
          page: routeState.page,
          filters: routeState.filters,
          hitsPerPage: routeState.hitsPerPage,
        },
      };
    },
  },
};

// application 'pages'
// import Home from './components/pages/Home';
import {
  Browse,
  Detail,
  Edit,
  CreateFromUrl,
  CreateFromIngredients,
  CreateFromImage,
  CreateMenu,
  Info,
  CreateFromEmbeddedLink,
  Home
} from './pages';

// theme
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";


export const App = () => {
  const { isLoading } = useAuth0();
  // TODO: Use FidgetSpinner here.

  const loggingSearchClient = useMemo(() => {
    return {
      ...Constants.searchClient,
      search(requests) {
        console.log("Search triggered")
        console.log("Number of queries per request:", requests.length);  
        /*console.trace("Search request stack trace");*/
        return Constants.searchClient.search(requests).then((response) => {
          console.log("Search response received:", response);
          return response;
        });
      },
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        // Scroll has occurred, hide the browser UI
        document.body.style.height = 'calc(100vh + 60px)';
      } else {
        // User is at the top, show the browser UI
        document.body.style.height = '100vh';
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (isLoading ? <div>Loading...</div> :
          <div className="fscontainer">
            <InstantSearch  
                searchClient={loggingSearchClient}
                indexName={Constants.ALGOLIA_INDEX_NAME}
                routing={routing}
                initialUiState={{
                  [Constants.ALGOLIA_INDEX_NAME]: {
                    page: 0,
                  },
                }}
            >
              <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Routes>
                  <Route path='/' element={<Home/>}/>
                  <Route path='/info' element={<Info/>}/>
                  <Route path='/browse' element={<AuthenticationGuard component={Browse}/>}/>
                  <Route path='/createMenu' element={<CreateMenu/>}/>
                  <Route path='/createFromUrl' element={<AuthenticationGuard component={CreateFromUrl}/>}/>
                  <Route path='/createFromIngredients'
                         element={<AuthenticationGuard component={CreateFromIngredients}/>}/>
                  <Route path='/createFromImage' element={<AuthenticationGuard component={CreateFromImage}/>}/>
                  <Route path='/edit/:guid' element={<AuthenticationGuard component={Edit}/>}/>
                  <Route path='/detail/:guid' element={<AuthenticationGuard component={Detail}/>}/>
                  <Route path='/el/:guid' element={<AuthenticationGuard component={CreateFromEmbeddedLink}/>}/>
                  <Route path="*" element={<Home/>}/>
                </Routes>
              </ThemeProvider>
            </InstantSearch>
          </div>
  )
}
