/*
Browse.js
Adam Berger
Jan 2025 
*/

import React, { useMemo, Component } from "react";
import PropTypes from 'prop-types';

import {
  Configure,
  Hits,
  Pagination,
} from "react-instantsearch-hooks-web";

import { DebouncedSearchBox } from "./DebouncedSearchBox";

import * as Constants from "components/Constants";

//import assets
import funnel from "assets/icons/funnel.svg";
import "satellite-min.css";
import "../../browse.scss";

//import components
import Navbar from "components/Navbar";
import {
  CustomStats,
  MultiSelectCheckbox,
  NutritionalFilterCheckboxes,
} from "../../components/BrowsePageComponents";
import { useBrowse } from "./useBrowse";
import WelcomeModal from "../../components/Welcome";
import { useFilterState } from "./useFilterState";

// Proper React Error Boundary for Algolia components
class AlgoliaErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Algolia component error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-message">
          There was an error loading the search results. Please try refreshing the page.
        </div>
      );
    }

    return this.props.children;
  }
}

AlgoliaErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export const Browse = () => {
  const filterState = useFilterState();
  const {
    nonce,
    numHits,
    Hit,
    HITS_PER_PAGE,
    welcomeModalIsOpen,
    disableWelcomeModal,
    setNumHits,
  } = useBrowse(filterState);

  return (
    <>
      <Navbar />
      <div style={{ overflowX: "hidden" }}>
        <div className="page_container recipe-section-container">
          <div key={nonce}>
            <AlgoliaErrorBoundary>
              <Configure
                hitsPerPage={HITS_PER_PAGE}
                filters={filterState.buildFilterQuery()}
                query={filterState.searchQuery}
              />
            </AlgoliaErrorBoundary>

            <div className="recipe-title-container">
              <div className="recipe-title">
                <h4 className="recipe-title-text">Recipes</h4>
                <p className="recipe-nums recipe-title-text">
                  &nbsp;({numHits})
                </p>
              </div>

              <div className="toggle-filters-container">
                <p className="toggle-filters-text">Filter recipes</p>
                <div
                  onClick={() => filterState.toggleSearchFilters()}
                  className="toggle-filters-button"
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      filterState.toggleSearchFilters();
                    }
                  }}
                >
                  <img src={funnel} alt="Filter" />
                </div>
              </div>
            </div>

            {/* Flex container */}
            <div className="browse-flex-container">
              {/* Left column for Search and Filters */}
              <div className="left-column">
                <DebouncedSearchBox 
                  placeholder="Search for anything ..." 
                  filterState={filterState}
                />
                
                {/* Toggle Button */}
                <div className={`filters ${filterState.showSearchFilters ? "show" : "hide"}`}>
                  <div className="recipes-view-toggle">
                    <label className="toggle-option">
                      <input
                        type="radio"
                        name="recipeOption"
                        value="myRecipes"
                        checked={filterState.onlyShowMyRecipes}
                        onChange={() => filterState.toggleOnlyShowMyRecipes(true)}
                      />
                      Only show my recipes
                    </label>
                    <label className="toggle-option">
                      <input
                        type="radio"
                        name="recipeOption"
                        value="allRecipes"
                        checked={!filterState.onlyShowMyRecipes}
                        onChange={() => filterState.toggleOnlyShowMyRecipes(false)}
                      />
                      Show everyone&apos;s recipes
                    </label>
                  </div>

                  <div className="filter-layout">
                    <div className="category-filter-container">
                      <div className="filter-title">Show these recipes: </div>
                      <MultiSelectCheckbox
                        categories={filterState.categoryFilters}
                        onToggle={filterState.toggleCategoryFilter}
                      />
                    </div>

                    <div className="nutritional-filter-container">
                      <div className="filter-title">Restrict to:</div>
                      <NutritionalFilterCheckboxes
                        filters={filterState.nutritionalFilters}
                        onToggle={filterState.toggleNutritionalFilter}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Right column for Search Results */}
              <div className="right-column">
                <CustomStats
                  setNumHits={setNumHits}
                  filters={filterState.nutritionalFilters}
                  categories={filterState.categoryFilters}
                />
                <AlgoliaErrorBoundary>
                  <div>
                    <Hits hitComponent={Hit} />
                  </div>
                  {numHits > HITS_PER_PAGE && (
                    <Pagination 
                      className="Pagination"
                      padding={3}
                      showFirst={true}
                      showLast={true}
                      showPrevious={true}
                      showNext={true}
                    />
                  )}
                </AlgoliaErrorBoundary>
              </div>
            </div>
          </div>
        </div>
      </div>
      {welcomeModalIsOpen && (
        <WelcomeModal toggle={disableWelcomeModal} />
      )}
    </>
  );
};
