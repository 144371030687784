import { React, useState } from "react";
import PropTypes from "prop-types";


const RenderIngredients = ({ ingredients, editable, onIngredientChange }) => {
 
  if (!ingredients || ingredients.length === 0) {
    ingredients = [] 
  }

  const [textValue, setTextValue] = useState(ingredients.join("\n"));
  
  const handleChange = (e) => {
    setTextValue(e.target.value);
    onIngredientChange(e)  
  }; 

  return (
    <div>
      {editable ? (
        <textarea
          value={textValue}
          onChange={handleChange}
          style={{
            width: "100%",
            minHeight: "10em",
            resize: "vertical",
            fontSize: "15px",
            padding: "8px",
          }}
        />
      ) : (
        ingredients.map((ingredient, index) => (
          <div key={index}>{ingredient}</div>
        ))
      )}
    </div>
  );
};
 

RenderIngredients.propTypes = {
  ingredients: PropTypes.arrayOf(PropTypes.string).isRequired,
  editable: PropTypes.bool,
  onIngredientChange: PropTypes.func,
};

RenderIngredients.defaultProps = {
  editable: false,
};

export default RenderIngredients;
 