import React from "react";
import PropTypes from "prop-types";
import {Checkbox} from "../base/Checkbox";

export const MultiSelectCheckbox = ({categories, onToggle}) => {
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridGap: "16px",
            }}
        >
            {categories.map((cat, index) => (
                <div className="filter-group" key={cat.label}>
                    <Checkbox
                        checked={cat.checked}
                        onChange={() => onToggle(cat.label)}
                        label={cat.label[0].toUpperCase() + cat.label.slice(1)}
                    />
                </div>
            ))}
        </div>
    );
}

MultiSelectCheckbox.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        checked: PropTypes.bool.isRequired
    })).isRequired,
    onToggle: PropTypes.func.isRequired,
}