import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import * as Constants from "../../components/Constants";
import { callExternalApi } from "../../components/external-api.service";
import { useNavigate } from "react-router";

export const useHome = () => {
  const { loginWithRedirect, isAuthenticated, getAccessTokenSilently } =
    useAuth0();
  const [emailIsVerified, setEmailIsVerified] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Handle update of window width. Determines whether we show the video or not.
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      // Cleanup: remove event listener when component unmounts
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    // Checking if user is loggedIn
    if (isAuthenticated) {
      // Checking if user verified email
      if (emailIsVerified === true) {
        // If so, redirect to browse page
        navigate("/browse");
      }
    }
  }, [navigate, isAuthenticated, emailIsVerified]);

  const loadUserDetail = async () => {
    let accessToken;
    try {
      accessToken = await getAccessTokenSilently();
    } catch (err) {
      console.log("Error getting access token:" + err);
      // If we get a refresh token error, try to re-authenticate
      if (err.message.includes("Missing Refresh Token")) {
        try {
          // Clear any existing tokens
          localStorage.clear();
          // Redirect to login
          loginWithRedirect({
            appState: { returnTo: window.location.pathname }
          });
        } catch (loginError) {
          console.log("Error during re-authentication:", loginError);
        }
      }
      return;
    }
    if (accessToken == null) {
      console.log("No access token - can't fetch user detail");
      return;
    }
    const url = Constants.AUTH0_USER_INFO_URL;
    const { data, error } = await callExternalApi(url, accessToken);

    if (!data) {
      console.log("No data returned from user detail API");
      return;
    }
    if (error) {
      console.log("Error returned from user detail:" + error);
      return;
    }
    if ("email_verified" in data && data["email_verified"] === true) {
      setEmailIsVerified(true);
      const value = localStorage.getItem("emailVerified");
      if (value === "false") {
        // The last time the user saw this screen on this device, they were unverified. Show them the one-time welcome message.
        localStorage.setItem("emailVerified", "true");
      }
    } else {
      setEmailIsVerified(false);
      localStorage.setItem("emailVerified", "false");
    }
  };

  const handleReload = () => {
    window.location.reload();
  };

  useEffect(() => {
    let isMounted = true;
    const loadDataWrapper = async () => {
      await loadUserDetail();
      if (!isMounted) {
        return;
      }
    };
    loadDataWrapper();
    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently]);

  return {
    emailIsVerified,
    isAuthenticated,
    windowWidth,
    loginWithRedirect,
    handleReload,
  };
};
