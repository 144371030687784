import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

export function DebouncedSearchBox({ placeholder, filterState }) {
  const [inputValue, setInputValue] = useState(filterState.searchQuery);

  // Update input value when filterState.searchQuery changes externally
  useEffect(() => {
    setInputValue(filterState.searchQuery);
  }, [filterState.searchQuery]);

  // Memoized debounced update function
  const debouncedUpdate = useMemo(
    () => debounce((value) => {
      filterState.updateSearchQuery(value);
    }, 300),
    [filterState]
  );

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    debouncedUpdate(newValue);
  };

  // Cleanup debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, [debouncedUpdate]);

  return (
    <div className="search-box-container">
      <div className="search-box">
        <input
          type="search"
          value={inputValue}
          onChange={handleChange}
          placeholder={placeholder}
          aria-label="Search recipes"
          className={filterState.isUpdating ? 'search-updating' : ''}
          style={{ 
            paddingLeft: '12px', 
            paddingRight: '12px',
            fontFamily: 'Mulish, sans-serif',
            fontSize: '14px',
            fontWeight: '400'
          }}
        />
        {filterState.isUpdating && (
          <div className="search-box-loading">
            <div className="search-box-loading-indicator"></div>
          </div>
        )}
      </div>
    </div>
  );
}

DebouncedSearchBox.propTypes = {
  placeholder: PropTypes.string,
  filterState: PropTypes.shape({
    searchQuery: PropTypes.string.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    updateSearchQuery: PropTypes.func.isRequired,
  }).isRequired,
};
  