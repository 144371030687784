/*
Constants.js
Constants and functions shared across the project

July 2023
Adam Berger
*/

import CryptoJS from "crypto-js";
import algoliasearch from "algoliasearch/lite";
import { v4 as uuidv4 } from "uuid";
import instantsearch from "instantsearch.js";
import bowlIcon from "assets/icons/BowlFood.svg";

export const AI_DISCLAIMER_MESSAGE = `
This service uses artificial intelligence to generate and/or edit recipes, nutritional information, \
images, and other content. No human reviews this content before you see it. We cannot \
guarantee that these recipes will be healthy or even suitable for consumption. \
Use your own judgment before following any recipe you see here.
`;

// Auth0-related. Find these bindings in .env file if running on localhost. If running on
// vercel, set bindings in the vercel console.
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_CALLBACK_URI = process.env.REACT_APP_AUTH0_CALLBACK_URL;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;

export const AUTH0_USER_INFO_URL = "https://" + AUTH0_DOMAIN + "/userinfo";

// These must match what's in the server side (common.py)
export const NINFO_PREFIX = "ninfo_";

// APIG-related
const SECURE_APIG_PREFIX = process.env.REACT_APP_APIG_PREFIX; // like: 'https://xyz.execute-api.us-east-1.amazonaws.com/'
export const APIG_SHARE_RESOURCE = "share";
export const APIG_DELETE_RESOURCE = "delete";
export const APIG_FEEDBACK_RESOURCE = "feedback";
export const APIG_CREATE_RESOURCE = "create";
export const APIG_CLEAN_RESOURCE = "clean";
export const APIG_GET_RESOURCE = "fetch";
export const APIG_UPDATE_RESOURCE = "update";
export const APIG_COPY_RESOURCE = "copy";

export function generateSecureApiUrl(resource, params) {
  return SECURE_APIG_PREFIX + resource + "?" + params;
}

// S3-related
export const PUBLIC_BUCKET_URL = "https://recipeguru-open.s3.amazonaws.com/";
export const LARGE_CHEF_IMAGE = bowlIcon;

// Old SRC: PUBLIC_BUCKET_URL + "app/chef.jpg"
export const SMALL_CHEF_IMAGE = bowlIcon;

export const LOGO_IMAGE = PUBLIC_BUCKET_URL + "app/logo-transparent.png";
export const DEFAULT_RECIPE_THUMBNAIL_IMAGE = SMALL_CHEF_IMAGE;
export const DEFAULT_FULL_RECIPE_IMAGE = LARGE_CHEF_IMAGE;
export const MADE_WITH_AI_BUTTON =
  PUBLIC_BUCKET_URL + "app/button-made-with-ai.png";

// videos on homescreen
export const HOMESCREEN_VIDEO_URLS = [
  PUBLIC_BUCKET_URL + "movies/movie0001.mp4",
  PUBLIC_BUCKET_URL + "movies/movie0002.mp4",
  PUBLIC_BUCKET_URL + "movies/movie0003.mp4",
  PUBLIC_BUCKET_URL + "movies/movie0004.mp4",
  PUBLIC_BUCKET_URL + "movies/movie0005.mp4",
  PUBLIC_BUCKET_URL + "movies/movie0006.mp4",
];

export const APP_HOME = "/";
export const APP_BROWSE = "/browse";

// routes to server endpoints
export const ROUTE_DETAIL = "/detail/";
export const ROUTE_EDIT = "/edit/";

// other configuration
export const FIXED_RECIPE_NAME_LENGTH_IN_GRID = 40;

// TODO: Consolidate these bindings with the same bindings in common.py
export const MIN_RECIPE_NAME_LENGTH = 5;
export const MAX_RECIPE_NAME_LENGTH = 40;
export const MAX_RECIPE_NOTES_LENGTH = 100;
export const MIN_RECIPE_BODY_LENGTH = 20;
export const MAX_RECIPE_INGREDIENTS_LENGTH = 5000;
export const MAX_RECIPE_INSTRUCTIONS_LENGTH = 5000;
export const MAX_RECIPE_BODY_LENGTH = 10000; // Users can create recipes of this size, although we impose a smaller ceiling on OpenAI generation
export const MAX_NINFO_LENGTH = 3000;

// TODO: fix redundant definition in common.py
export const CATEGORIES = [
  "none",
  "soup",
  "salad",
  "breakfast",
  "entree",
  "dessert",
  "snack",
  "beverage",
  "bread",
  "other",
];
export const DEFAULT_CATEGORY = CATEGORIES[0];

export const VISIBILITY_PRIVATE = "private";
export const VISIBILITY_PUBLIC = "public";

// Algolia constants and initialization. Note that only some are exported.
// Also note there is an analogous initialization on the server side (common.py)
const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID;
const ALGOLIA_SEARCH_KEY = process.env.REACT_APP_ALGOLIA_SEARCH_KEY;

export const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY, {
  timeouts: {
    connect: 2000, // Connection timeout in ms
    read: 5000,    // Read timeout in ms
    write: 3000,   // Write timeout in ms
  },
});

export const ALGOLIA_INDEX_NAME = "recipes";

searchClient.initIndex(ALGOLIA_INDEX_NAME);

const ALGOLIA_INSTANTSEARCH = instantsearch({
  indexName: ALGOLIA_INDEX_NAME,
  searchClient: algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY),
});
ALGOLIA_INSTANTSEARCH.start();

export function refreshIndex() {
  ALGOLIA_INSTANTSEARCH.refresh();
}

export const timeout = (ms, promise) => {
  let timer;
  return Promise.race([
    promise,
    new Promise(
      (_, reject) =>
        (timer = setTimeout(() => reject(new Error("Timeout")), ms))
    ),
  ]).finally(() => clearTimeout(timer));
};

function convert_from_json_to_readable(x) {
  try {
    // Parse the JSON string into an object
    const jsonObj = JSON.parse(x);

    // Create an array of strings for each key-value pair
    const lines = Object.keys(jsonObj).map((key) => {
      return `${key}: ${jsonObj[key]}`;
    });

    // Join the array elements into a single string with new line separators
    return lines.join("\n");
  } catch (error) {
    // Parsing as JSON failed, so it's likely plain text
    return x;
  }
}

// fill missing fields with defaults, etc.
export function trueUpRecipe(r) {
  let r2 = r;

  // derive a 'lastmodified_date' field from the 'tse' field'
  r2.lastmodified_date = new Date(r.tse * 1000).toLocaleString();

  // ninfo is stored in JSON - serialize it here
  if ("ninfo" in r) {
    r2.ninfo = convert_from_json_to_readable(r.ninfo);
  }

  if (!("visibility" in r)) {
    r2.visibility = VISIBILITY_PRIVATE;
  }

  if (!("category" in r) || !r.category) {
    r2.category = DEFAULT_CATEGORY;
  }

  if (!("notes" in r)) { 
      r2.notes = ""
  }

  if (!("vc" in r)) {
    r2.vc = "";
  }

  if (!("owner" in r)) {
    r2.owner = "unknown";
  }

  return r2;
}

/* convenience function that wraps getAccessTokenSilently and, if it fails/hangs, invokes to user login flow */
export const getAccessTokenSilentlyWithTimeout = async (
  getAccessTokenSilently,
  loginWithRedirect,
  navigate
) => {
  var accessToken = null;
  try {
    accessToken = await timeout(5000, getAccessTokenSilently());
  } catch (e) {
    console.log("Error getting access token: " + e);
    loginWithRedirect();
    navigate(APP_HOME);
  }
  return accessToken;
};

export function generateGUID() {
  return uuidv4().replace(/-/g, ""); // remove the hyphens
}
export function encodeUser(user) {
  const email = user["name"]; // Auth0 stores UUID here
  return encodeEmail(email);
}

export function encodeEmail(email) {
  return CryptoJS.SHA256(email).toString();
}

export function countLinesInString(s) {
  return s.split(/\r?\n/).length;
}

export function isEntirelyWhitespace(s) {
  return s.trim().length === 0;
}

export function isValidUrl(str) {
  /*
  This function uses a regular expression to check the following:

The overall length of each label (the parts between dots) is between 1 and 63 characters.
Labels do not start or end with a hyphen.
The top-level domain (TLD) is at least two characters long, does not start or end with a hyphen, and consists only of letters.
The entire FQDN ends with a dot or nothing (optional trailing dot).
Keep in mind:

This regex does not account for all valid TLDs (new TLDs are regularly added).
It does not validate against the actual DNS system, just the general format of an FQDN.
Internationalized domain names (IDN) will not pass unless they are provided in Punycode format.
Some rare but valid domain names may not pass this regex due to specific character combinations.
*/
  try {
    let url;

    // If the string does not contain protocol, assume http for the purpose of domain extraction
    if (!str.includes("://")) {
      url = new URL("http://" + str);
    } else {
      url = new URL(str);
    }

    const fqdn = url.hostname;

    // Simple regex for a valid domain name (with subdomains allowed)
    const fqdnRegex =
      /^(?!-)[a-zA-Z0-9-]{1,63}(\.(?!-)[a-zA-Z0-9-]{1,63})*\.(?=[a-zA-Z]{2,63})(?!-)[a-zA-Z-]{2,63}$/;
    return fqdnRegex.test(fqdn);
  } catch (e) {
    return false;
  }
}

export function getImageMimeTypeFromHeader(header) {
  // these are the only ones we accept for now
  const mimeTypes = {
    "89504e47": "image/png",
    ffd8ffe0: "image/jpeg",
    ffd8ffe1: "image/jpeg",
    ffd8ffe2: "image/jpeg",
    "49492a00": "image/tiff",
    "4d4d002a": "image/tiff",
  };
  return mimeTypes[header] || "";
}

export function encodeUserInputForURI(s) {
  /* wrapper around encodeURIComponent. Use this before putting user input in a URI */
  if (s === undefined) {
    return "";
  }
  return encodeURIComponent(_removeUnsavoryCharacters(s));
}

function _removeUnsavoryCharacters(str) {
  /* removes all unprintable chars except newline */
  if (str === undefined) {
    return "";
  }
  return str.replace(/[^\x20-\x7E\n]/g, "").replace(/\\/g, "");
}


export const resizeImage = (file, maxWidth = 1000, maxHeight = 1000) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      img.src = e.target.result;
    };

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      let { width, height } = img;

      // Resize maintaining aspect ratio
      if (width > maxWidth || height > maxHeight) {
        const aspectRatio = width / height;
        if (width > height) {
          width = maxWidth;
          height = Math.round(maxWidth / aspectRatio);
        } else {
          height = maxHeight;
          width = Math.round(maxHeight * aspectRatio);
        }
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          if (blob) {
            const resizedFile = new File([blob], file.name, {
              type: file.type,
              lastModified: Date.now(),
            });
            resolve(resizedFile);
          } else {
            reject(new Error("Canvas resizing failed"));
          }
        },
        file.type,
        0.9 // Quality factor for compression
      );
    };

    img.onerror = (e) => reject(e);
    reader.readAsDataURL(file);
  });
};


// Helper to parse Ingredients 
export const extractIngredients = (recipeBody) => { 
  if (typeof recipeBody !== "string") return [];
 
  const lines = recipeBody.split("\n");

  // Find the index of the "Ingredients" header with flexible formatting
  const startIndex = lines.findIndex((line) =>
    /^\W*(ingredients)\W*$/i.test(line.trim()) // Matches "Ingredients" with possible symbols around it
  );

  if (startIndex === -1) return [];

  const ingredients = [];
  
  // Collect lines until we hit a clear "Instructions" or similar section
  for (let i = startIndex + 1; i < lines.length; i++) {
    const trimmed = lines[i]
      .trim()
      .replace(/^[-*■]+\s*/, "") // Remove leading bullets, asterisks, etc.
      .replace(/^\*+|\*+$/g, ""); // Remove bold/markdown

    // Stop when reaching "Instructions" or similar, ensuring it's on a line by itself
    if (/^\W*(instructions|directions|steps|procedure|preparation|method)\W*$/i.test(trimmed)) {
      break;
    }

    if (!trimmed) continue; // Skip empty lines

    ingredients.push(trimmed);
  }

  return ingredients;
};

 
// Helper to parse Instructions 
export function extractInstructions(recipeBody) {
  if (typeof recipeBody !== "string") return [];

/*
  console.log("XXXXX");
  console.log(recipeBody);
  console.log("XXXXX");
*/

  const lines = recipeBody.split("\n").map(line => line.trim()).filter(line => line !== "");

  // Find the index of the "Instructions" header
  const startIndex = lines.findIndex(line =>
    /^\W*(instructions|directions|steps|procedure|preparation|method)\W*$/i.test(line)
  );

  if (startIndex === -1) return [];

  // Extract lines after "Instructions"
  const instructionLines = lines.slice(startIndex + 1);

  // Regular expression to detect numbered steps
  const numberedStepRegex = /^(Step \d+|\d+\.\s|\d+\)\s|\d+\s-\s|First|Second|Third)/i;
  const stepsAreNumbered = instructionLines.some(line => numberedStepRegex.test(line));

  let formattedSteps = [];
  let currentStep = "";

  for (let i = 0; i < instructionLines.length; i++) {
    let line = instructionLines[i];

    if (numberedStepRegex.test(line)) {
      // If we already have a current step, save it before starting a new one
      if (currentStep) formattedSteps.push(currentStep.trim());
      currentStep = line; // Start a new step
    } else if (currentStep === "") {
      // Each line should be its own step if unnumbered
      formattedSteps.push(line);
    } else {
      // Append to the current step if it's a continuation
      currentStep += " " + line;
    }
  }

  if (currentStep) formattedSteps.push(currentStep.trim()); // Add the last step

  // If steps are not numbered, add numbering (one per line)
  if (!stepsAreNumbered) {
    formattedSteps = formattedSteps.map((step, index) => `${index + 1}. ${step}`);
  }

  return formattedSteps;
}


/**
 * Parses a nutritional information string into an object with numeric values and units.
 * 
 * - Extracts numeric values, computing the mean for ranges (e.g., "700-900 kcal" → 800).
 * - Preserves units (e.g., "35g" → `{ value: 35, unit: "g" }`).
 * - Stores non-numeric values like "Serving Size: 1/4 of the recipe" as a string.
 * - Defaults to `0` if no numeric value is found.
 * 
 * @param {string} text - The nutritional information string, formatted as "key: value unit".
 * @returns {Object} An object where each key maps to `{ value: number|string, unit: string }`.
 * 
 * @example
 * const text = "Calories: 700-900 kcal\nProtein: 35-45g\nServing Size: 1/4 of the recipe";
 * console.log(parseNutritionalInfo(text));
 * // Output:
 * // {
 * //   calories: { value: 800, unit: "kcal" },
 * //   protein: { value: 40, unit: "g" },
 * //   serving size: { value: "1/4 of the recipe", unit: "" }
 * // }
 */
export function parseNutritionalInfo(text) {
  if (!text) return {}; // Handle empty input safely

  return text.split("\n").reduce((obj, line) => {
    const trimmedLine = line.trim();
    if (!trimmedLine || !trimmedLine.includes(":")) return obj; // Skip empty/malformed lines

    let [key, value] = trimmedLine.split(/\s*:\s*/);
    if (!key || !value) return obj; // Ensure both key and value exist

    key = key.trim().toLowerCase().replace(/['"]+/g, ""); // Clean key
    value = value.trim().replace(/['"]+/g, ""); // Remove quotes

    // Special case: Preserve fractions and non-numeric serving sizes
    if (key.includes("serving size")) {
      obj[key] = { value, unit: "" };
      return obj;
    }

    // Extract numeric values, allowing for ranges (e.g., "700-900 kcal")
    const match = value.match(/[\d/]+/g); // Find all numbers including fractions
    if (match) {
      const numbers = match.map((num) => (num.includes("/") ? num : Number(num))); // Convert whole numbers, preserve fractions

      const avgValue =
        numbers.length === 2 && typeof numbers[0] === "number" && typeof numbers[1] === "number"
          ? Math.round((numbers[0] + numbers[1]) / 2) // Compute and round mean
          : numbers.length === 1 && typeof numbers[0] === "number"
          ? numbers[0] // Use the single number
          : value; // Default to full string if it contains non-numeric values

      // Extract units (anything non-numeric after the last number)
      const unitMatch = value.match(/[a-zA-Z%]+/g); // Find units
      const unit = unitMatch ? unitMatch.join("").trim() : ""; // Join to get full unit string

      obj[key] = { value: avgValue, unit };
    } else {
      // If no valid number is found, preserve as a string (e.g., serving sizes)
      obj[key] = { value, unit: "" };
    }

    return obj;
  }, {});
}
