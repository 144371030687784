import React, { useState } from "react";
import PropTypes from "prop-types";

const RenderInstructions = ({ instructions, editable, onInstructionChange }) => {
  if (!instructions || instructions.length === 0) {
    instructions = []
  }

  const [textValue, setTextValue] = useState(instructions.join("\n"));
 
  const handleChange = (e) => {
      setTextValue(e.target.value);
      onInstructionChange(e)
    }; 

  return (
    <div>
      {editable ? (
        <textarea
          value={textValue}
          onChange={handleChange}
          style={{
            width: "100%",
            minHeight: "10em",
            resize: "vertical",
            fontSize: "15px",
            padding: "8px",
          }}
        />
      ) : (
        instructions.map((instruction, index) => (
            <p key={index}>{instruction}</p> 
        ))
      )}
    </div>
  );
};

RenderInstructions.propTypes = {
  instructions: PropTypes.arrayOf(PropTypes.string).isRequired,
  editable: PropTypes.bool,
  onInstructionChange: PropTypes.func,  
};

RenderInstructions.defaultProps = {
  editable: false,
};


export default RenderInstructions;
