import React, { useEffect, useState, useRef } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useInstantSearch } from "react-instantsearch-hooks-web";
import PropTypes from "prop-types";
import * as Constants from "../../components/Constants";
import * as LocalCache from "../../components/LocalCache";
import { Link } from "react-router-dom";
import WrappedImg from "../../components/WrappedImg";
import {
  FILTER_STICKY_DURATION,
  INITIAL_NUTRITIONAL_FILTER_STATE,
  FILTER_MAPPINGS,
} from "../../settings";
import { callExternalApi } from "../../components/external-api.service";

let HITS_PER_PAGE = 15; // must be divisible by 3, since we're using 3 columns for wide-screen displays

// Debounce utility function
const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const useBrowse = (filterState) => {
  const { getAccessTokenSilently } = useAuth0();

  const [welcomeModalIsOpen, setWelcomeModalIsOpen] = useState(false);

  const disableWelcomeModal = () => {
    console.log("Disabling welcome modal")
    localStorage.setItem("userVisited", "true");
    setWelcomeModalIsOpen(false);
  };

  const loadUserDetail = async () => {
    let accessToken;
    try {
      accessToken = await getAccessTokenSilently();
    } catch (err) {
      console.log("Error getting access token:" + err);
      return;
    }
    if (accessToken == null) {
      console.log("No access token - can't fetch user detail");
      return;
    }
    const url = Constants.AUTH0_USER_INFO_URL;
    const { data, error } = await callExternalApi(url, accessToken);

    if (!data) {
      console.log("No data returned from user detail API");
      return;
    }
    if (error) {
      console.log("Error returned from user detail:" + error);
      return;
    }
  };

  useEffect(() => {
    const visited = localStorage.getItem("userVisited");
    if (visited === "true") {
      setWelcomeModalIsOpen(false);
    } else {
      setWelcomeModalIsOpen(true);
    }
  }, []);


  useEffect(() => {
    let isMounted = true;
    const loadDataWrapper = async () => {
      await loadUserDetail();
      if (!isMounted) {
        return;
      }
    };
    loadDataWrapper();
    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently]);

  const [numHits, setNumHits] = useState(0);
  const { user } = useAuth0();
  const { refresh } = useInstantSearch();

  //check for window size
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    //update screen size state
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // add event listener to update screen size and update hits per page accordingly
    window.addEventListener("resize", handleResize);
    /*    window.addEventListener("resize", adjustHits); */

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
      /*      window.addEventListener("resize", adjustHits); */
    };
  }, []);

  // Data fetched from LocalCache (representing recently added, deleted, and edited recipes)
  const [recentlyAddedRecipes, setRecentlyAddedRecipes] = useState(new Set());
  const [recentlyDeletedRecipes, setRecentlyDeletedRecipes] = useState(
    new Set(),
  );
  const [recentlyEditedRecipes, setRecentlyEditedRecipes] = useState(new Set());
  const [namesOfRecipes, setNamesOfRecipes] = useState({});
  const [imagesOfRecipes, setImagesOfRecipes] = useState({});

  // used to force a reload of the search results
  const [nonce, setNonce] = useState(0);

  // track if we're currently refreshing to prevent multiple refreshes
  const isRefreshing = useRef(false);
  // track refresh counts to prevent infinite refreshing
  const refreshCount = useRef(0);

  // on mobile, the filters panel can collapse
  const [showSearchFilters, setShowSearchFilters] = useState(false);

  const [onlyShowMyRecipes, setOnlyShowMyRecipes] = useState(true);
  const [nutritionalFilters, setNutritionalFilters] = useState(
    INITIAL_NUTRITIONAL_FILTER_STATE,
  );

  useEffect(() => {
    const onlyShow = getFromLocalStorage("onlyShowMyRecipes", true);
    const nutritionalState = getFromLocalStorage(
      "nutritionalFilterState",
      INITIAL_NUTRITIONAL_FILTER_STATE,
    );

    setOnlyShowMyRecipes((prev) => (prev !== onlyShow ? onlyShow : prev));
    setNutritionalFilters((prev) =>
      JSON.stringify(prev) !== JSON.stringify(nutritionalState)
        ? nutritionalState
        : prev,
    );
  }, []);

  // show/hide the search filters
  const toggleSearchFilters = () => {
    setShowSearchFilters((prevState) => !prevState);
  };

  function toggleOnlyShowMyRecipes(b) {
    setOnlyShowMyRecipes(b);
    putToLocalStorage("onlyShowMyRecipes", b);
  }

  const toggleFilter = (filterName) => {
    setNutritionalFilters((prevState) => {
      const newState = {
        ...prevState,
        [filterName]: !prevState[filterName],
      };
      putToLocalStorage("nutritionalFilterState", newState);
      return newState;
    });
  };

  
  function buildFilterQuery() {
    // step 1: build nutritional filters
    let nutritionalFilters = Object.entries(nutritionalFilters)
      .filter(([, value]) => value)
      .map(([key]) => FILTER_MAPPINGS[key])
      .join(" AND ");
    nutritionalFilters = nutritionalFilters
      ? ` AND (${nutritionalFilters})`
      : "";

    // step 2: build category filters
    let categoryFilters = filterState.categoryFilters
      .filter((cat) => cat.checked)
      .map((cat) => `category:'${cat.label}'`);
    categoryFilters = categoryFilters.join(" OR ");
    categoryFilters = categoryFilters ? ` AND (${categoryFilters})` : "";
    if (categoryFilters === "") {
      categoryFilters = ' AND category:"xx"';
    }

    // step 3: apply 'only show my recipes' flag, if active
    let onlyMineFilter = "";
    if (onlyShowMyRecipes) {
      onlyMineFilter = " AND userid: " + Constants.encodeUser(user);
    }

    let finalQuery = `${nutritionalFilters} ${categoryFilters} ${onlyMineFilter}`;
    finalQuery = finalQuery.replace(
      /\s*AND /,
      "",
    ); /* remove leading 'AND', if present */
    return finalQuery;
  }


  // Custom hook for managing localStorage with timestamps
  const useLocalStorageWithExpiry = (key, defaultValue, duration = FILTER_STICKY_DURATION) => {
    const getValue = () => {
      try {
        const savedData = JSON.parse(localStorage.getItem(key));
        const currentTime = new Date().getTime();

        if (savedData && currentTime - savedData.timestamp < duration) {
          return savedData.state;
        }

        localStorage.removeItem(key); // Clear expired data
        return defaultValue;
      } catch (error) {
        console.error(`Error reading ${key} from localStorage:`, error);
        return defaultValue;
      }
    };

    const setValue = (value) => {
      try {
        const stateWithTimestamp = {
          state: value,
          timestamp: new Date().getTime(),
        };
        localStorage.setItem(key, JSON.stringify(stateWithTimestamp));
      } catch (error) {
        console.error(`Error writing ${key} to localStorage:`, error);
      }
    };

    return [getValue, setValue];
  };

  // Using the hook at component level
  const [getFromLocalStorage, putToLocalStorage] = useLocalStorageWithExpiry('filterState');

  // We want to give new recipes time before rendering them, to wait for their thumbnail to be available.
  // To that end, this is a dictionary that associates a state with every possible guid.
  // Allowable values are None (never saw this guid), WAITING_FOR_THUMBNAIL (it's been <60s since first seeing it), and TIMED_OUT (timer has expired).
  const WAITING_FOR_THUMBNAIL = "waiting_for_thumbnail",
    TIMED_OUT = "timed_out";
  const [waitingForThumbnail, setWaitingForThumbnail] = useState({});

  // Track created objectURLs to clean them up
  const objectURLsRef = useRef(new Map());

  // Clean up object URLs when component unmounts
  useEffect(() => {
    return () => {
      // Revoke all object URLs to prevent memory leaks
      objectURLsRef.current.forEach(url => {
        try {
          URL.revokeObjectURL(url);
        } catch (e) {
          console.error("Error revoking object URL:", e);
        }
      });
      objectURLsRef.current.clear();
    };
  }, []);

  async function refreshCache() {
    if (isRefreshing.current) {
      console.debug("Skipping refresh - already in progress");
      return; // Prevent concurrent refreshes
    }

    const startTime = performance.now();
    isRefreshing.current = true;

    try {
      console.debug("Starting cache refresh");

      // First refresh the search index
      try {
        refresh(); // force a reload of the search results
      } catch (refreshError) {
        console.error("Error refreshing search index:", refreshError);
        // Continue with cache refresh even if search refresh fails
      }

      // Then refresh the local cache
      try {
        const [addedRecipes, deletedRecipes, editedRecipes, names, images] =
          await LocalCache.get();

        console.debug(`Cache refreshed - ${addedRecipes.size} added, ${deletedRecipes.size} deleted, ${editedRecipes.size} edited recipes`);

        setRecentlyAddedRecipes(addedRecipes);
        setRecentlyDeletedRecipes(deletedRecipes);
        setRecentlyEditedRecipes(editedRecipes);
        setNamesOfRecipes(names);
        setImagesOfRecipes(images);
      } catch (cacheError) {
        console.error("Error fetching data from LocalCache:", cacheError);
        // Don't rethrow - we want to complete the operation
      }
    } finally {
      isRefreshing.current = false;
      const endTime = performance.now();
      console.debug(`Cache refresh completed in ${(endTime - startTime).toFixed(2)}ms`);
    }
  }

  // Add performance monitoring
  const perfMetricsRef = useRef({
    refreshCount: 0,
    totalRefreshTime: 0,
    lastRefreshTime: 0
  });

  // Update performance metrics
  const updatePerfMetrics = (startTime) => {
    const endTime = performance.now();
    const duration = endTime - startTime;

    perfMetricsRef.current = {
      refreshCount: perfMetricsRef.current.refreshCount + 1,
      totalRefreshTime: perfMetricsRef.current.totalRefreshTime + duration,
      lastRefreshTime: duration
    };

    // Log detailed metrics when debug is enabled
    if (process.env.NODE_ENV === 'development') {
      console.debug(`Cache refresh #${perfMetricsRef.current.refreshCount} took ${duration.toFixed(2)}ms (avg: ${(perfMetricsRef.current.totalRefreshTime / perfMetricsRef.current.refreshCount).toFixed(2)}ms)`);
    }
  };

  // populate cache on initial load
  useEffect(() => {
    const startTime = performance.now();
    refreshCache().then(() => updatePerfMetrics(startTime));
  }, []);

  // separate effect for when nonce changes - refreshes cache but doesn't set up new intervals
  useEffect(() => {
    if (nonce > 0) {
      const startTime = performance.now();
      refreshCache().then(() => updatePerfMetrics(startTime));
    }
  }, [nonce]);

  // while there's pending recipes, keep refreshing the page every n seconds
  const TIME_BETWEEN_REFRESHES_WHEN_RECIPES_ARE_PENDING = 20000; // 20s
  const MAX_REFRESHES = 3; // Stop after 3 refreshes

  // Create a more comprehensive recipe status tracking system
  const recipeStatusRef = useRef({
    pendingRecipes: new Set(),
    lastRefreshTime: 0,
    scheduledRefresh: null,
    refreshCount: 0
  });

  useEffect(() => {
    // Update pending recipes tracking
    if (recentlyAddedRecipes.size !== recipeStatusRef.current.pendingRecipes.size) {
      // Recipe count has changed
      const oldSize = recipeStatusRef.current.pendingRecipes.size;
      const newSize = recentlyAddedRecipes.size;

      // Update the set
      recipeStatusRef.current.pendingRecipes = new Set([...recentlyAddedRecipes]);

      console.debug(`Recipe count changed: ${oldSize} -> ${newSize}`);

      // Reset refresh count if we have new recipes
      if (newSize > oldSize) {
        recipeStatusRef.current.refreshCount = 0;
        console.debug("New recipes detected, resetting refresh count");
      }
    }

    // Clear any existing scheduled refresh
    if (recipeStatusRef.current.scheduledRefresh) {
      clearTimeout(recipeStatusRef.current.scheduledRefresh);
      recipeStatusRef.current.scheduledRefresh = null;
    }

    // Schedule new refreshes if needed
    if (recentlyAddedRecipes.size > 0 && recipeStatusRef.current.refreshCount < MAX_REFRESHES) {
      console.debug(`Scheduling refresh #${recipeStatusRef.current.refreshCount + 1} of ${MAX_REFRESHES}`);

      recipeStatusRef.current.scheduledRefresh = setTimeout(() => {
        if (recipeStatusRef.current.refreshCount >= MAX_REFRESHES) {
          console.log("Reached maximum refresh count, stopping automatic refreshes");
          return;
        }

        refreshCache();
        setNonce(n => n + 1);
        recipeStatusRef.current.refreshCount++;
        recipeStatusRef.current.lastRefreshTime = Date.now();

        console.log(`Auto-refreshed search results (${recipeStatusRef.current.refreshCount}/${MAX_REFRESHES})`);
      }, TIME_BETWEEN_REFRESHES_WHEN_RECIPES_ARE_PENDING);
    }

    // Cleanup on unmount
    return () => {
      if (recipeStatusRef.current.scheduledRefresh) {
        clearTimeout(recipeStatusRef.current.scheduledRefresh);
      }
    };
  }, [recentlyAddedRecipes.size, refreshCache]); // Only depend on the size, not the nonce

  // Reset the state on unmount
  useEffect(() => {
    return () => {
      setNumHits(0);
    };
  }, []); // Empty dependency array to run only on mount and unmount

  /* render one search result */
  const HitComponent = ({ hit }) => {
    const guid = hit.guid;

    // don't render deleted recipes
    if (recentlyDeletedRecipes.has(guid)) {
      // Only refresh if we're not already refreshing
      if (!isRefreshing.current) {
        refresh();
      }
      return null;
    }

    let recipe_name = hit.recipe_name;
    let thumb_image_url = hit.thumb_image_url; // Default: Algolia's stored thumbnail

    // Use cached data for recently edited recipes
    if (recentlyEditedRecipes.has(guid)) {
      // Recipe was recently edited. Algolia's data is likely out of date. Use LocalCache instead.
      recipe_name = namesOfRecipes[guid] || recipe_name; // Fallback to algolia data if not in cache
      const recipe_image = imagesOfRecipes[guid];
      if (recipe_image) {
        // Create object URL only when needed and store it to avoid recreating on each render
        thumb_image_url = URL.createObjectURL(recipe_image);
      }

      // Handle recently added recipes that now appear in search results
      if (recentlyAddedRecipes.has(guid)) {
        // This recipe is now appearing in the Algolia search results.
        // Use useEffect to handle this case to avoid triggering during render
        React.useEffect(() => {
          const handleRecipeAppeared = async () => {
            await LocalCache.deleteByGuid(guid);
            // Don't call refreshCache here, let the interval handle it
          };

          handleRecipeAppeared();

          if (!(guid in waitingForThumbnail)) {
            // Recipe just appeared in 'recentlyAddedRecipes' for the first time
            setWaitingForThumbnail(prev => ({
              ...prev,
              [guid]: WAITING_FOR_THUMBNAIL
            }));

            const timeoutId = setTimeout(() => {
              setWaitingForThumbnail((prevState) => ({
                ...prevState,
                [guid]: TIMED_OUT,
              }));
            }, 30000);

            // Clean up timeout if component unmounts
            return () => clearTimeout(timeoutId);
          }
        }, [guid]);
      }
    }

    const url = Constants.ROUTE_DETAIL + guid;
    const key = guid + thumb_image_url; // unique key for this hit

    const userOwnsThisRecipe = hit.userid === Constants.encodeUser(user);

    return (
      <Link to={url}>
        <div className="browse-grid-item" key={key}>
          <div className="thumb_image_container">
            <WrappedImg
              src={thumb_image_url}
              defaultSrc={Constants.DEFAULT_RECIPE_THUMBNAIL_IMAGE}
              className="thumb_image"
            />
          </div>
          <p>
            {hit.error ? (
              <>Couldn&apos;t create recipe (click for details)</>
            ) : (
              <>{userOwnsThisRecipe ? <>{recipe_name}</> : <em>{recipe_name}</em>}</>
            )}
          </p>
        </div>
      </Link>
    );
  };

  // Wrap the component with React.memo after defining propTypes
  const Hit = React.memo(HitComponent);

  // Define propTypes on the original component
  HitComponent.propTypes = {
    hit: PropTypes.shape({
      guid: PropTypes.string.isRequired,
      userid: PropTypes.string.isRequired,
      recipe_name: PropTypes.string.isRequired,
      thumb_image_url: PropTypes.string.isRequired,
      error: PropTypes.string, // optional
    }).isRequired,
  };
  
  // Explicitly set display name for ESLint react/display-name rule
  Hit.displayName = 'Hit';

  return {
    nonce,
    buildFilterQuery,
    numHits,
    toggleSearchFilters,
    showSearchFilters,
    onlyShowMyRecipes,
    toggleOnlyShowMyRecipes,
    nutritionalFilters,
    toggleFilter,
    setNumHits,
    Hit,
    HITS_PER_PAGE,
    putToLocalStorage,
    recentlyAddedRecipes,
    welcomeModalIsOpen,
    disableWelcomeModal,
    recentlyDeletedRecipes,
  };
};