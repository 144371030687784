/*
index.jsx (for Detail component)
Adam Berger
July 2023
*/

import React, { useRef, useEffect } from "react";
import Navbar from "components/Navbar";
import MKTypography from "components/MKTypography";
import * as Constants from "components/Constants";
import ShareModal from "components/ShareModal";
import DeleteModal from "components/DeleteModal";
import VariantModal from "components/VariantModal";
import RenderIngredients from "components/RenderIngredients";
import RenderInstructions from "components/RenderInstructions";
import ModalWithAck from "components/ModalWithAck";
import RecipeFooter from "components/RecipeFooter";
import LoadingSpinner from "components/LoadingSpinner";
import { useDetail } from "./useDetail";
import { CustomButton } from "../../components/base/CustomButton";

// import assets
import "../../detail.css";
import share from "assets/icons/share.svg";
import sliders from "assets/icons/sliders.svg";
import pencil from "assets/icons/pencil.svg";
import trash from "assets/icons/trash.svg";
import utensils from "assets/icons/utensils.svg";
import magicWand from "assets/icons/MagicWand.svg";
import gear from "assets/icons/GearSix.svg";
import slidersGrey from "assets/icons/SlidersHorizontalGrey.svg";
import shareGrey from "assets/icons/ShareGrey.svg";
import gearGrey from "assets/icons/GearSixGrey.svg";
import pencilGrey from "assets/icons/PencilGrey.svg";
import trashGrey from "assets/icons/TrashGrey.svg";

const WILL_MAKE_A_COPY_MESSAGE =
  "This recipe belongs to another Kasserole user. By editing, you will create your own copy of this recipe.";

const MAX_QTY_BADGES = 3;

/* TODO: move this logic out of here */
const nutritionalValues = [
  { value: "calories", limit: 400, label: "Low Calorie" },
  { value: "sodium", limit: 200, label: "Low Sodium" },
  { value: "sugars", limit: 15, label: "Low Sugar" },
  { value: "dietaryFiber", limit: 5, label: "High Fiber", isHigh: true },
  { value: "protein", limit: 20, label: "High Protein", isHigh: true },
  { value: "fat", limit: 10, label: "Low Fat" },
  { value: "carbohydrates", limit: 30, label: "Low Carb" },
];

export const Detail = () => {
  const {
    guid,
    actionsDropdownIsActive,
    handleActionsClick,
    setActionsDropdownIsActive,
    recipe,
    errorModalIsActive,
    errorTitle,
    errorMessage,
    setErrorModalIsActive,
    navigate,
    setVariantModalIsActive,
    setShareModalIsActive,
    prepareToEdit,
    userOwnsThisRecipe,
    setDeleteModalIsActive,
    setDisclaimerModalIsActive,
    shareModalIsActive,
    deleteModalIsActive,
    variantModalIsActive,
    willCopyThisRecipeModalIsActive,
    setWillCopyThisRecipeModalIsActive,
    navigateToEdit,
    guidToEdit,
    disclaimerModalIsActive,
    sharedFromModalIsActive,
    setSharedFromModalIsActive,
    generateSharedRecipeMessage,
  } = useDetail();

  // Ref + click-outside for the mobile dropdown in "Actions"
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Handler to check if user clicked outside dropdown
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setActionsDropdownIsActive(false); // close it
      }
    }
    // Add a global "mousedown" listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setActionsDropdownIsActive]);

  if (errorModalIsActive) {
    return (
      <>
        <Navbar />
        <ModalWithAck
          title={errorTitle}
          message={errorMessage}
          onClick={() => {
            setErrorModalIsActive(false);
            navigate(-1);
          }}
        />
      </>
    );
  }

  // if we're still loading the recipe, show a spinner
  if (!recipe || !recipe.recipe_name) {
    return <LoadingSpinner />;
  }


  // Build the recipe object. Ingredients and instructions are embedded in recipe.recipe_body; we parse them 
  // out into their own strings. NutritionalAnalysis is extracted from the recipe.ninfo string. 
  // dxxxxrecipe.ninfoObj = createNinfoObj(recipe);
  // const nutritionalAnalysis = getNinfoObj(recipe.ninfo);
  const ingredients = Constants.extractIngredients(recipe.recipe_body);
  const instructions = Constants.extractInstructions(recipe.recipe_body);
  const notes = recipe.notes || "(No notes, but feel free to edit the recipe to add some!)"
  const nutritionalAnalysis = Constants.parseNutritionalInfo(recipe.ninfo)

  return (
    <>
      <Navbar />
      <div className="page_container_full">
        {/* HERO AREA */}
        <div className="hero-area">
          <div className="detail-img-container">
            {recipe.full_image_url ? (
              <img src={recipe.full_image_url} className="full_image_view" />
            ) : (
              <div className="full_image_view" />
            )}
            <div className="detail-img-overlay"></div>

            {/*
              1. Instead of pushing everything to the bottom, 
              split into a top and bottom container with 
              `justify-content: space-between`.
            */}
            <div className="detail-hero-content">
              {/* TOP container: Badges + Title */}
              <div className="detail-hero-top">
                <div className="detail-badge-container">
                  <div className="detail-hero-badge">
                    <img src={utensils} alt="category" />
                    {recipe.category[0].toUpperCase() + recipe.category.slice(1)}
                  </div>
                  {nutritionalValues
                    .filter(
                      ({ value, limit, isHigh }) =>
                        (isHigh && nutritionalAnalysis[value] > limit) ||
                        (!isHigh && nutritionalAnalysis[value] < limit)
                    )
                    .slice(0, MAX_QTY_BADGES)
                    .map(({ label }, key) => (
                      <div key={key} className="detail-hero-badge">
                        {label}
                      </div>
                    ))}
                </div>

                <h2 className="detail-main-text">{recipe.recipe_name}</h2>
              </div>

              {/* BOTTOM container: Actions, Additional Buttons, etc. */}
              <div className="detail-hero-bottom">
                <CustomButton
                  imgSrc={!actionsDropdownIsActive ? gear : gearGrey}
                  text="Actions"
                  className={
                    !actionsDropdownIsActive
                      ? "detail-hero-actions-btn"
                      : "detail-hero-actions-btn-active"
                  }
                  iconClassName=""
                  onClickAction={handleActionsClick}
                />

                <div className="detail-hero-btn-container">
                  <CustomButton
                    imgSrc={sliders}
                    imgSrcHover={slidersGrey}
                    text="Diet / Allergies"
                    className="detail-hero-btn detail-share-btn"
                    iconClassName="detail-share-icon"
                    onClickAction={() => setVariantModalIsActive(true)}
                  />
                  <CustomButton
                    imgSrc={share}
                    imgSrcHover={shareGrey}
                    text="Share"
                    className="detail-hero-btn detail-share-btn"
                    iconClassName="detail-share-icon"
                    onClickAction={() => setShareModalIsActive(true)}
                  />
                  <CustomButton
                    imgSrc={pencil}
                    imgSrcHover={pencilGrey}
                    text="Edit"
                    className="detail-hero-btn detail-share-btn"
                    iconClassName="detail-share-icon"
                    onClickAction={() => prepareToEdit(guid)}
                  />
                  {userOwnsThisRecipe && (
                    <CustomButton
                      imgSrc={trash}
                      imgSrcHover={trashGrey}
                      text="Delete"
                      className="detail-hero-btn detail-share-btn"
                      iconClassName="detail-share-icon"
                      onClickAction={() => setDeleteModalIsActive(true)}
                    />
                  )}
                </div>

                {actionsDropdownIsActive && (
                  <div
                    className="detail-hero-btn-container-mobile"
                    ref={dropdownRef}
                  >
                    <CustomButton
                      imgSrc={slidersGrey}
                      text="Diet / Allergies"
                      className="detail-hero-btn-mobile"
                      iconClassName="detail-icon-mobile"
                      onClickAction={() => setVariantModalIsActive(true)}
                    />
                    <CustomButton
                      imgSrc={shareGrey}
                      text="Share"
                      className="detail-hero-btn-mobile"
                      iconClassName="detail-icon-mobile"
                      onClickAction={() => setShareModalIsActive(true)}
                    />
                    <CustomButton
                      imgSrc={pencilGrey}
                      text="Edit"
                      className="detail-hero-btn-mobile"
                      iconClassName="detail-icon-mobile"
                      onClickAction={() => prepareToEdit(guid)}
                    />
                    {userOwnsThisRecipe && (
                      <CustomButton
                        imgSrc={trashGrey}
                        text="Delete"
                        className="detail-hero-btn-mobile"
                        iconClassName="detail-icon-mobile"
                        onClickAction={() => setDeleteModalIsActive(true)}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* DESKTOP ONLY: Nutritional Analysis container */}
          <div className="nutritional-analysis-container-desktop">
            <div className="detail-right-title-container">
              <div className="detail-container-title">Nutritional Analysis</div>
              <CustomButton
                imgSrc={magicWand}
                text="Made With AI"
                className="detail-ai-badge"
                iconClassName=""
                onClickAction={() => setDisclaimerModalIsActive(true)}
              />
            </div>
            <MKTypography variant="caption">
              <ul className="preserveformatting detail-right-body-text">

                {Object.entries(nutritionalAnalysis).map(([key, data], i) => (
                  <li key={i}>
                    <span style={{ fontWeight: "bold" }}>{key}:</span> {data.value} {data.unit}
                  </li>
                ))}

              </ul>
            </MKTypography>
          </div>
        </div>
        {/* END HERO AREA */}

        {/* MAIN CONTENT */}
        <div className="detail-content-container">
          <div className="detail-left-container">
            <div className="detail-left-title-container">
              <div className="detail-container-title">Recipe</div>
              {recipe.bguai === "True" && (
                <CustomButton
                  imgSrc={magicWand}
                  text="Made With AI"
                  className="detail-ai-badge"
                  iconClassName=""
                  onClickAction={() => setDisclaimerModalIsActive(true)}
                />
              )}
            </div>

            <div className="preserveformatting detail-left-body-text">
              <label className="input-label">Ingredients:</label>
              <RenderIngredients ingredients={ingredients} />
              <br/>
              <label className="input-label">Instructions:</label>
              <RenderInstructions instructions={instructions} />

              <div className="preserveformatting detail-left-body-text">
                <label className="input-label">Notes:</label>
                  {notes}
              </div>

            </div>

            <div className="detail-left-footer-container">
              <RecipeFooter
                sourceUrl={recipe.source_url}
                tse={recipe.tse}
                vc={recipe.vc}
                sharer={recipe.sf}
                userOwnsRecipe={userOwnsThisRecipe}
                visibility={recipe.visibility}
              />
              <div className="detail-left-footer-views"></div>
              <div className="detail-left-footer-date"></div>
            </div>
          </div>

          <div className="detail-right-container">
            <div className="detail-right-title-container">
              <div className="detail-container-title">Nutritional Analysis</div>
              <CustomButton
                imgSrc={magicWand}
                text="Made With AI"
                className="detail-ai-badge"
                iconClassName=""
                onClickAction={() => setDisclaimerModalIsActive(true)}
              />
            </div>
            <MKTypography variant="caption">
              <ul className="preserveformatting detail-right-body-text">

                {Object.keys(nutritionalAnalysis).map((key, index) => (
                  <li key={index}>
                    <span>{key.replace("_", " ")}:</span>{" "}
                    <span>{nutritionalAnalysis[key].value} {nutritionalAnalysis[key].unit}</span>
                  </li>
                ))}

              </ul>
            </MKTypography>
            <div className="detail-right-footer-container">
              <RecipeFooter
                sourceUrl={recipe.source_url}
                tse={recipe.tse}
                vc={recipe.vc}
                sharer={recipe.sf}
                userOwnsRecipe={userOwnsThisRecipe}
                visibility={recipe.visibility}
              />
              <div className="detail-left-footer-views"></div>
              <div className="detail-left-footer-date"></div>
            </div>
          </div>
        </div>
      </div>

      {/* Modals */}
      {renderModal(
        ShareModal,
        shareModalIsActive,
        () => setShareModalIsActive(!shareModalIsActive),
        { guid }
      )}
      {renderModal(
        DeleteModal,
        deleteModalIsActive,
        () => setDeleteModalIsActive(!deleteModalIsActive),
        { guid }
      )}
      {renderModal(
        VariantModal,
        variantModalIsActive,
        () => setVariantModalIsActive(!variantModalIsActive),
        { guid }
      )}
      {renderModal(
        ModalWithAck,
        willCopyThisRecipeModalIsActive,
        () => setWillCopyThisRecipeModalIsActive(!willCopyThisRecipeModalIsActive),
        {
          title: "Create a Copy",
          copy: true,
          message: WILL_MAKE_A_COPY_MESSAGE,
          onClick: () => navigateToEdit(guidToEdit),
        }
      )}
      {renderModal(
        ModalWithAck,
        disclaimerModalIsActive,
        () => setDisclaimerModalIsActive(!disclaimerModalIsActive),
        {
          title: "AI-Generated Content",
          message: Constants.AI_DISCLAIMER_MESSAGE,
        }
      )}
      {renderModal(
        ModalWithAck,
        sharedFromModalIsActive,
        () => setSharedFromModalIsActive(!sharedFromModalIsActive),
        {
          title: "Shared Recipe",
          message: generateSharedRecipeMessage(recipe.sf),
        }
      )}
    </>
  );
};

const renderModal = (ModalComponent, isActive, toggleFunction, additionalProps) => {
  return (
    isActive && (
      <ModalComponent
        toggle={toggleFunction}
        backdrop={true}
        onClick={toggleFunction}
        {...additionalProps}
      />
    )
  );
};
