import {useStats} from "react-instantsearch-hooks-web";
import React, {useEffect, useState} from "react";
import bowlFood from "../../assets/icons/BowlFood.svg";
import PropTypes from "prop-types";
import LoadingSpinner from "../LoadingSpinner";


export const CustomStats = ({setNumHits, filters, categories}) => {
    const {nbHits, query} = useStats();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // delay loading the custom stats by 100s to avoid race condition (TODO: more elegant solution?)
        const timer = setTimeout(() => {
            if (nbHits !== undefined && nbHits !== null) {
                setNumHits(nbHits);
                setIsLoading(false);
            }
        }, 100);
        return () => clearTimeout(timer);
    }, [
        nbHits,
        query,
        setNumHits,
        filters,
        categories,
    ]);

    if (nbHits > 0) {
        return null;
    }

    return (
        <div className="empty-results-container">
            {
                isLoading ? <LoadingSpinner/> : <>
                    <img src={bowlFood}/>
                    <div className="empty-results-text">No recipes match that search.</div>
                </>
            }
        </div>
    );
}

CustomStats.propTypes = {
    setNumHits: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
}